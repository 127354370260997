.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* FORM */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-wight);
    font-weight: 700;
    font-family: 'Fira Sans';
}

p {
    color: var(--color-primary);
    font-size: 1.2rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .contact__option {
        width: 40%;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact__options {
        flex-direction: row;
        justify-content: center;
        gap: 1.5rem;
    }

    .contact__option h5 {
        display: none;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact__options {
        flex-direction: row;
        justify-content: center;
    }

    .contact__option {
        width: 40%;
    }

    .contact__option h5 {
        display: none;
    }

    .contact__option h4 {
        font-size: 0.83rem;
    }

    .contact__option a {
        font-size: 0.7rem;
    }
}