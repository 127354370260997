header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* CTA BUTTONS */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* HEADER SOCIALS */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ME */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 22rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
}

/* SCROLL DOWN */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 7rem;
    transform: rotate(90deg);
    font-weight: 500;
    font-size: 0.9rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    header {
        height: 95vh;
    }

    .me {
        margin-top: 6rem;
        width: 36rem;
        height: 36rem;
        position: absolute;
        left: calc(50% - 18rem);
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
    }

    .cta {
        margin-top: 5rem;
    }

    .scroll__down {
        right: -4.5rem;
        bottom: 7rem;
        font-weight: 500;
        font-size: 1rem;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        padding-top: 3rem;
    }

    .header__container h5 {
        font-size: 0.69rem;
    }

    .header__socials, .scroll__down {
        display: none;
    }

    .cta {
        margin-top: 2rem;
    }

    .cta a {
        font-size: 0.75rem;
    }

    .me {
        width: 20rem;
        height: 20rem;
        position: absolute;
        left: calc(50% - 10rem);
        margin-top: 4rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
    }
}